button {
    height: 32px;
    width: 78px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 12px;
    cursor: pointer;
    border: 2px solid var(--primary-black);
    background-color: white;
}

button:disabled {
    cursor: default;
}

.button-large {
    width: 224px;
    height: 56px;
    font-size: 18px;
}

.button-primary-inverted {
    color: white;
    border: 2px solid white;
    background-color: var(--primary-black);
}

.button-primary-inverted:hover {
    color: var(--primary-black);
    border: 2px solid white;
    background-color: white;
}

.button-primary-inverted:disabled {
    background-color: var(--primary-black);
    color: var(--primary-grey);
    border-color: var(--primary-black);
    opacity: 0.2;
}

.button-cta {
    color: white;
    background-color: var(--primary-black);
    border-color: var(--primary-black);
}

.button-cta:hover {
    color: white;
    opacity: 0.9;
    -webkit-transition: opacity 250ms linear;
    -ms-transition: opacity 250ms linear;
    transition: opacity 250ms linear;
}

.button-cta:disabled {
    background-color: var(--primary-grey);
    color: var(--primary-black);
    border-color: var(--primary-grey);
    opacity: 0.5;
}

.button-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-black);
    font-size: 18px;
    height: 56px;
    width: 188px;
    border-radius: 12px;
    border: var(--primary-black) 2px solid;
    text-decoration: none;

    &:hover {
        background-color: var(--primary-black);
        color: #ffffff;
        opacity: 1;
    }
}

.button-link-inverted {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 18px;
    height: 56px;
    width: 188px;
    border-radius: 12px;
    border: var(--primary-black) 2px solid;
    text-decoration: none;
    background-color: var(--primary-black);

    &:hover {
        background-color: #ffffff;
        color: var(--primary-black);
        opacity: 1;
    }
}
