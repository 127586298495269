@import '/styles/variables.scss';

:root {
    --primary-black: #262423;
    --primary-black-50: #26242380;
    --primary-black-80: #262423d9;
    --primary-black-10: #2624231a;
    --primary-grey: #e2e3e6;
    --primary-grey-50: #e2e3e680;
    --primary-light-grey: #f5f6fa;
    --secondary-light-grey: #f8f9fc;
    --primary-orange: #f89f1b;
    --secondary-orange: #fde561;
    --primary-red: #f06562;
    --secondary-red: #fbd5d4;
    --primary-blue: #00adee;
    --secondary-blue: #b9e9fa;
    --primary-green: #37b34a;
    --secondary-green: #c8eace;
    --font-dark-grey: #757575;
}

@font-face {
    font-family: 'Formular';
    src: url('/fonts/Formular.otf') format('opentype');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Formular Italic';
    src: url('/fonts/Formular-Italic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Formular Medium';
    src: url('/fonts/Formular-Medium.otf') format('opentype');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Formular Medium Italic';
    src: url('/fonts/Formular-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Formular Bold';
    src: url('/fonts/Formular-Bold.otf') format('opentype');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'Formular Bold Italic';
    src: url('/fonts/Formular-BoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Formular Light';
    src: url('/fonts/Formular-Light.otf') format('opentype');
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'Formular Light Italic';
    src: url('/fonts/Formular-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

* {
    font-family: Formular, Arial, Helvetica, sans-serif;
}

html,
body {
    font-size: 16px;
    color: var(--primary-black);
    margin: 0;
    padding: 0;
}

body {
    overflow-y: scroll;
}

a {
    color: var(--primary-black);
    text-decoration: underline black;
    text-underline-offset: 3px;
}

a:hover {
    opacity: 0.5;
}

a:focus-visible {
    border-radius: 0;
    outline: 2px solid var(--primary-orange);
    outline-offset: -2px;
}

button:focus-visible {
    border-radius: 24px;
    outline: 3px solid var(--primary-orange);
    outline-offset: 0;
}

button:active,
a:active {
    outline: none;
}

h1,
h2,
h3,
h4 {
    font-weight: 500;
}

h1,
h2 {
    font-size: 16px;
    color: var(--primary-black);

    @include desktop {
        font-size: $text-18px-in-vw;
    }

    @include large {
        font-size: 18px;
    }
}

h3 {
    font-size: 36px;

    @include desktop {
        font-size: $text-36px-in-vw;
    }

    @include large {
        font-size: 36px;
    }
}

h4 {
    font-size: 18px;

    @include desktop {
        font-size: $text-18px-in-vw;
    }

    @include large {
        font-size: 18px;
    }
}

p {
    color: var(--primary-black);
}

.active {
    color: #186aa5;
}

/* https://github.com/h5bp/html5-boilerplate/blob/dbc3ed973573a77122f6b8a2aebd0a76a44ad6a6/src/css/main.css#L153
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.screen-reader-only {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap; /* 1 */
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.screen-reader-only.focusable:active,
.screenReaderOnly.focusable:focus {
    clip: auto;
    clip-path: none;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
    white-space: inherit;
}

.rc-tooltip {
    opacity: 1 !important;
    max-width: 500px;
    .rc-tooltip-inner {
        background-color: var(--primary-black);
        border-radius: 0;
    }
}
